<template>
    <div>
        <Navbar page="Gerar questionário de mapeamento" link="/mapeamento" nameLink="Mapeamento de atividades de tratamento" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h2 class="text-2xl text-gray-900 font-semibold mb-5">
                Gerar questionário de mapeamento de atividade
            </h2>
            <button @click="atividades.push({ nome: '', setor: null, responsavel: null })" type="button" class="inline-flex justify-center py-2 px-6 mb-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                Adicionar nova atividade
            </button>
            <button @click="$modal.show('importarModal')" class="inline-flex justify-center py-2 px-6 mb-5 md:ml-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg> 
            Gerar múltiplos QMA'S
            </button>
            <div v-for="(form, idx) in atividades" :key="idx" class="grid grid-cols-12 gap-4 my-2">
                <div class="col-span-12 md:col-span-3">
                    <label for="name" class="block text-sm font-medium">
                        Nome da Atividade
                    </label>
                    <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                </div>
                <div class="col-span-12 md:col-span-3">
                    <label for="setor" class="block text-sm font-medium"> 
                        Setor
                    </label>
                    <select v-model="form.setor" name="setor" id="setor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                    </select>
                </div>
                <div class="col-span-12 md:col-span-3">
                    <label for="tipo_responsavelQMA" class="block text-sm font-medium" :class="!form.setor ? 'opacity-50' : ''">Responsável direto pelo preenchimento do QMA</label>
                    <select @change="validaTipoResponsavel(form)" required v-model="form.tipo_responsavelQMA" :disabled="!form.setor ? true : false" name="tipo_responsavelQMA" id="tipo_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option value="consultor">Próprio consultor (responsável do login administrador)</option>
                        <option value="profissional">Outro profissional no projeto</option>
                        <option value="pessoa">Uma pessoa na empresa</option>
                    </select>
                </div>
                <div v-if="form.tipo_responsavelQMA === 'consultor'" class="col-span-12 md:col-span-3">
                    <label class="block text-sm font-medium">
                        Responsável preenchimento do QMA
                    </label>
                    <p class="text-sm mt-4">
                        {{ $store.state.user.name }}
                    </p>
                </div>
                <div v-if="form.tipo_responsavelQMA === 'profissional'" class="col-span-12 md:col-span-3">
                    <label for="profissional_responsavelQMA" class="block text-sm font-medium"> Se "profissional" indique o nome do profissional</label>
                    <select required v-model="form.profissional_responsavelQMA" name="profissional_responsavelQMA" id="profissional_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
                    </select>
                </div>
                <div v-if="form.tipo_responsavelQMA === 'pessoa'" class="col-span-12 md:col-span-3">
                    <label for="pessoa_responsavelQMA" class="block text-sm font-medium"> Se "pessoa na empresa" indique o nome da pessoa</label>
                    <select v-model="form.pessoa_responsavelQMA" name="pessoa_responsavelQMA" id="pessoa_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                    </select>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <button @click="atividades.splice(idx, 1)" class="text-white hover:bg-red-500 bg-red-700 text-sm rounded py-1 px-4 mx-1">
                        Remover
                    </button>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <hr />
                </div>
            </div>
            <div class="grid grid-cols-2">
                <div class="px-5 py-3 text-left sm:px-6">
                </div>
                <div class="py-3 text-right">
                    <button @click="gerar()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Gerar
                    </button>
                </div>
            </div>
            <modal name="importarModal" :adaptive="true" :height="'auto'">
                <div class="px-5 py-5">
                    <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
                    <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-7.xlsx`" class="block text-sm underline mb-2">Exemplo arquivo modelo importação</a>
                        <a target= "_blank" rel="noreferrer" :href="`/criacao_atividades.html`" class="block text-sm underline mb-2">Informações sobre campos no arquivo/planilha modeloo</a>
                    </div>
                    <div class="col-span-12">
                        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
                        </vue-dropzone>
                    </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>
<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      atividades: [
        {
          setor: null, 
          tipo_responsavelQMA: '',
          pessoa_responsavelQMA:  null,
          profissional_responsavelQMA: null,
          nome: '',
        } 
      ],
      keys: [],
      pessoas: [],
      setores: [],
      profissionais: [],
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/atividades/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      })
    }
  },
  methods: {
    validaTipoResponsavel(form) {
        form.pessoa_responsavelQMA = null;
    },
    async start(){
        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true });
        this.setores = listSetores.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true });
        this.pessoas = listPessoas.data.data;

        const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`);  
        this.profissionais = profissionaisRef.data.data;
    },
    async gerar() {
        this.$confirm({
            title: 'Gerar atividades',
            message: `Gerar as atividades selecionadas ?`,
            button: {
                no: 'Não',
                yes: 'Sim',
            },
            callback: async confirm => {
                if(!confirm) return
                const req = await this.$http.post(`/v1/atividades/criar`, { atividades: this.atividades });
                 if (req.data.success) {
                    this.$vToastify.success("Geradas com sucesso!");
                    this.$router.push({path: `/mapeamento`});
                } else {
                    this.$vToastify.error(req.data.err);
                }
            }
        });
    },

    send(file, xhr, formData) {
      formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    async update(file, response) {
        await this.start();
        if(!response.atividades.length) this.$vToastify.error("Arquivo inválido! Verificar campos utilizandos no arquivo modelo.");
        for(let i = 0; i < response.atividades.length; i++){
            const atividade = response.atividades[i];
            this.atividades.push(atividade);
        }
        this.keys = response.keys;
        this.$modal.hide('importarModal');
    },
  },
  async beforeMount() {
    this.start();
  },
}
</script>